<template>
  <div class="my-ads">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-top-25">
        <div class="row margin-0 margin-bottom-15">
          <div class="col pad-0 pad-top-5">
            <h4 class="ad-type">Users <span v-if="products && products.results.length">({{products.count}})</span> <span v-if="loading">Loading....</span></h4>
          </div>
          <div class="col pad-0 text-right">
            <button class="btn btn-light btn-sm bold" @click="getProducts()"><ion-icon name="refresh-outline"></ion-icon></button>
          </div>
        </div>
        <div class="row margin-0 list-head">
          <div class="col-md-5 col-12">
            <h5>Name</h5>
          </div>
          <div class="col-md-3 col-12 hidden-xs">
            <h5>Mobile &amp; Email</h5>
          </div>
          <div class="col hidden-xs">
            <h5>Path</h5>
          </div>
        </div>
        <template v-if="products && products.results.length>0">
          <div v-for="item in products.results" :key="item.id" class="row margin-0 list-body">
            <div class="col-md-5 col-12">
              <h5><b>{{item.name}}</b> - <span class="txt-grey margin-bottom-0">{{item.user_type}}</span></h5>
            </div>
            <div class="col-md-3 col-12">
              <h5><b>{{item.mobile}}</b> <span v-if="item.email" class="txt-grey margin-bottom-0"><br> {{item.email}}</span></h5>
            </div>
            <div class="col-md-4 col-12">
              <time-label :date="new Date(item.created_on)"></time-label><br>
              {{item.path}}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row margin-0">
            <div class="col-12 text-center pad-30">
              <!-- <button class="btn btn-success btn-sm margin-bottom-5" @click="$router.push('/post-free-ad/')">+ Post free Ad</button><br> -->
              <span class="txt-grey">No Ads added till now</span>
            </div>
          </div>
        </template>
        <br><br>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            products: null,
            loading: true
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        getProducts() {
          this.loading = true;
          this.$cs.user.list({
            sort: 'created_on',
            pageSize: 20
          })
          .then(res => {
            this.products = res.data;
            this.loading = false;
          })
        },
    }
}
</script>
<style lang="stylus" scoped>
.my-ads
  min-height 80vh
  background-color #ffffff
  .ad-type
    color #333333
    letter-spacing 0.3px
  .list-head
    background-color #eeeeee
    border-radius 4px
    div[class^="col"], div[class*=" col"]
        padding 15px
        h5
          margin 0px
          font-weight bold
  .list-body
    border-bottom 1px solid #eee
    div[class^="col"], div[class*=" col"]
        padding-top 10px
    .price
      color green
      font-weight 600
    .cat-label
      font-size 0.8em
      color #666666
@media (max-width: 767px)
  .my-ads
    min-height 80vh
    .list-body
      border 1px solid #dddddd
      margin 10px 0px
      padding 5px 0px
      border-radius 4px
      div[class^="col"], div[class*=" col"]
        padding-top 5px
      .b-top
        border-top 1px solid #eee
</style>
